.custom-yellow {
  color: #f9ee20 !important;
}

.custom-purple {
  color: #622f8f !important;
}

.custom-bg-purple {
  background-color: #622f8f !important;
}

.custom-bg-yellow {
  background-color: #f9ee20 !important;
}

.gallery_post_two .img:before {
  background-color: rgb(98, 47, 143, 0.8);
}

.custom-subTitle {
  line-height: 1.4;
  font-size: 20px;
}

.custom-list-cl li a {
  color: #f9ee20 !important;
}

.scroll_service_item {
  height: 280px !important;
  margin-bottom: 32px;
  background-color: #622f8f;
  /* border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px; */
}
.scroll_service_item h3 {
  color: #f9ee20 !important;
}

.scroll_service_item .number {
  color: #f9ee20 !important;
  opacity: 0.8;
  font-family: "Playfair Display", serif;
}

.scroll_service_item:before {
  background: #f9ee20;
  height: 5px !important;
  /* background-color: rgba(0, 0, 0, 0.616); */
}

.project-sec {
  /* background: #622f8f; */
  padding: 120px 0;
}

/* .gallery_post_two .img img {
  object-fit: cover !important;
  height: 100% !important;
  width: 100% !important;
} */
/* .mainMenu_3 ul li :hover {
  color: #622f8f !important;
}

.sub-menu ul li a {
  color: #622f8f !important;
} */

.feature_section_3 {
  position: relative;
  padding: 85px 0 98px;
  /* background: url(../images/bg/4.jpg) no-repeat fixed center center / cover; */
  background: none;
  background-color: #f9ee20;
}

.singleProject img {
  width: 100%;
  height: 250px;
  transition: all ease 400ms;
  -moz-transition: all ease 400ms;
  -webkit-transition: all ease 400ms;
}
